@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/* Base styles */
* {
  margin: 0;
  padding: 0;
  font-family: "Quicksand";
  box-sizing: border-box;
}

*:not(.codeblock *) {
  color: #333;
}

*.theme-changed:not(.codeblock *) {
  animation-name: changeThemeTextWhiteToDark;
  animation-duration: 1s;
}

body.dark-content *:not(input, button) {
  color: hsl(210, calc(1 * 2.9%), 86.7%);
}

body.theme-changed.dark-content *:not(input, button, .codeblock *) {
  animation-name: changeThemeTextDarktoWhite;
  animation-duration: 1s;
}

@keyframes changeThemeTextWhiteToDark {
  from {
    color: hsl(210, calc(1 * 2.9%), 86.7%);
  }
  to {
    color: #333;
  }
}

@keyframes changeThemeTextDarktoWhite {
  from {
    color: #333;
  }
  to {
    color: hsl(210, calc(1 * 2.9%), 86.7%);
  }
}

#root {
  contain: paint;
}

body.dark-content {
  background-color: hsl(220, calc(1 * 7.7%), 22.9%);
}

nav {
  height: 70px;
  width: 100vw;
  background: rgb(233, 230, 230);
  filter: drop-shadow(0px 0px 2px #000);
  position: sticky;
  z-index: 999;
  top: 0;
  overflow: hidden;
}

.App {
  min-height: calc(100vh - 70px - 70px);
  overflow-x: hidden;
}

body.dark-content .App {
  background-color: #36393e;
}

body.theme-changed.dark-content .App {
  animation-name: changeThemeBgWhiteToDark;
  animation-duration: 1s;
}

body:not(.dark-content) .App {
  background-color: #fff;
}

body.theme-changed:not(.dark-content) .App {
  animation-name: changeThemeBgDarkToWhite;
  animation-duration: 1s;
}

@keyframes changeThemeBgWhiteToDark {
  from {
    background-color: #fff;
  }
  to {
    background-color: #36393e;
  }
}

@keyframes changeThemeBgDarkToWhite {
  from {
    background-color: #36393e;
  }
  to {
    background-color: #fff;
  }
}

.App > div:not(:first-child) {
  padding: 40px 20vw calc(40px + 2.5rem) 20vw;
  display: flex;
  flex-direction: column;
  row-gap: 10vh;
}

.App > div:not(:first-child) h2 {
  margin: 0 0 2.5vh 0;
}

@media only screen and (max-width: 1366px) {
  .App > div:not(:first-child) {
    padding: 40px 10vw;
  }
}

@media only screen and (max-width: 801px) {
  .App > div:not(:first-child) {
    padding: 40px 5vw;
  }
}

footer {
  height: 70px;
  width: 100vw;
  background: #111;
  box-shadow: 0px 0px 3px #000;
  position: relative;
  padding: 0 20px;
}

section {
  width: 100%;
  max-width: 420px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.4);
}

form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
}

hr:not([class]) {
  background-color: #fff;
  padding: 0;
  margin: 80px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

body.dark-content hr:not([class]) {
  background-color: #36393e;
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.75),
    rgba(255, 255, 255, 0)
  );
}

hr.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: 0.5;
}

hr.hr-text:before {
  content: "";
  /* use the linear-gradient for the fading effect */
  /* use a solid background color for a solid bar */
  background: linear-gradient(to right, transparent, #818078, transparent);
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

body.dark-content hr.hr-text:after {
  background-color: #36393e !important;
}

hr.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;

  padding: 0 0.5em;
  line-height: 1.5em;

  /* this is really the only tricky part, you need to specify the background color of the container element... */
  color: #818078;
  background-color: #fff;
}

body.dark-content a,
body.dark-content a:visited {
  color: hsl(210, calc(1 * 2.9%), 86.7%);
}

a,
a:visited {
  color: #333;
}

a:hover {
  cursor: pointer;
}

.Contact p.moreinfo {
  margin: 4vh 0 4vh 6.75vw;
}

body.dark-content .Contact p.moreinfo a,
body.dark-content .Contact p.moreinfo a:visited {
  color: hsl(210, calc(1 * 2.9%), 86.7%);
}

.Contact p.moreinfo a,
.Contact p.moreinfo a:visited {
  color: #333;
}

input[type="text"],
input[type="password"],
button,
textarea {
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
}

label,
button {
  margin-top: 1rem;
}

button {
  padding: 0.5rem;
}

button:hover {
  cursor: pointer;
}

button:disabled {
  cursor: not-allowed;
}

nav ol {
  height: 100%;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 20px;
  overflow: hidden;
  display: flex;
  float: right;
  height: 100%;
}

nav ul > li {
  height: 100%;
  display: flex;
  align-items: center;
}

nav ul > li:not(:last-child):after {
  content: "|";
}

nav ul > li a {
  color: #333 !important;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 35px;
  text-decoration: none;
  font-weight: 900;
}

nav ul > li a svg {
  display: none !important;
}

nav ul > li a:hover {
  background: rgba(255, 255, 255, 0.5);
}

header .hamburger {
  padding-right: 20px;
}

header input,
header span,
header .hamburger {
  display: none;
}

@media only screen and (max-width: 1366px) {
  header {
    height: 70px;
    width: 100vw;
    background: rgb(233, 230, 230);
    filter: drop-shadow(0px 0px 2px #000);
    position: sticky;
    z-index: 999;
    top: 0;
    overflow: hidden;
  }

  nav {
    position: absolute;
    right: 0;
    height: 100%;

    /* position: sticky; */
    height: calc(100vh - 70px);
    filter: unset;
    top: 70px;
    float: right;
    width: 50vw;
    z-index: 500;
    background: #333;

    margin-right: -50vw;
  }

  nav ul {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    overflow-y: auto;
  }

  nav ul li {
    height: unset;
    display: flex;
    min-height: 8vh;
    background: #444;
    align-items: center;
    justify-content: flex-start;
  }

  nav ul > li a {
    color: #fff !important;
    display: flex;
    padding: 0 0 0 20px;
    font-size: 1.1rem;
    width: 100%;
  }

  nav ul > li a svg {
    padding-left: 0.3rem;
    display: inline !important;
  }

  nav ul > li a svg * {
    color: #fff;
  }

  header .hamburger {
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    float: right;
  }

  header .hamburger input {
    display: block;
    width: 33px;
    height: calc(4px + 5px + 4px + 5px + 4px);
    position: absolute;

    cursor: pointer;

    opacity: 0%;
    z-index: 2;

    -webkit-touch-callout: none;
    justify-content: center;
  }

  header .hamburger span {
    display: block;
    width: 33px;
    height: 4px;
    position: relative;

    background: #cdcdcd;
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }

  header .hamburger span:not(:last-child) {
    margin-bottom: 5px;
  }

  header .hamburger span:nth-child(2) {
    transform-origin: 0% 0%;
  }

  header .hamburger span:nth-child(3) {
    transform-origin: 0% 100%;
  }

  header .hamburger input:checked ~ span:nth-last-child(3) {
    opacity: 1;
    transform: rotate(45deg) translate(5px, -4px);
    background: #232323;
  }

  header .hamburger input:checked ~ span:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  header .hamburger input:checked ~ span:nth-last-child(1) {
    opacity: 1;
    transform: rotate(-45deg) translate(0px, 4px);
    background: #232323;
  }

  header .hamburger input:checked ~ ul {
    transform: none;
  }

  nav ol > ul > li:not(:last-child):after,
  nav ol > ul > li:after {
    content: unset;
  }

  nav.open {
    margin-right: 0;
    animation: openHambugerMenu 1s;
  }

  nav:not(.resizing).close {
    margin-right: -50vw;
    animation: closeHambugerMenu 1s;
  }

  @keyframes openHambugerMenu {
    from {
      margin-right: -50vw;
    }
    to {
      margin-right: 0;
    }
  }

  @keyframes closeHambugerMenu {
    from {
      margin-right: 0;
    }
    to {
      margin-right: -50vw;
    }
  }
}

.UserLayout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0.5rem;
  background-color: dodgerblue;
  background-image: url("./assets/images/loginBg.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: inherit;
}

body.theme-changed .UserLayout {
  animation-name: changeThemeUserLayoutDarktoWhite;
  animation-duration: 1s;
}

body.dark-content .UserLayout {
  background-color: #2a3439;
}

body.theme-changed.dark-content .UserLayout {
  animation-name: changeThemeUserLayoutWhiteToDark;
  animation-duration: 1s;
}

@keyframes changeThemeUserLayoutWhiteToDark {
  from {
    background-color: dodgerblue;
  }
  to {
    background-color: #2a3439;
  }
}

@keyframes changeThemeUserLayoutDarktoWhite {
  from {
    background-color: #2a3439;
  }
  to {
    background-color: dodgerblue;
  }
}

body.dark-content .UserLayout.danger {
  background-color: darkred;
  -webkit-animation: slowlydarktored 1s;
  animation: slowlydarktored 1s;
}

body.dark-content .UserLayout.resolve {
  -webkit-animation: slowlyredtodark 1s;
  animation: slowlyredtodark 1s;
}

.UserLayout.danger {
  background-color: darkred;
  -webkit-animation: slowlybluetored 1s;
  animation: slowlybluetored 1s;
}

.UserLayout.resolve {
  -webkit-animation: slowlyredtoblue 1s;
  animation: slowlyredtoblue 1s;
}

.KeyVisual {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./assets/images/keyvisual.jpg");
  height: 50vh;
  background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

@media only screen and (max-width: 1366px) {
  .KeyVisual {
    height: 30vh;
    background-attachment: scroll;
  }
}

.KeyVisualText {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  margin: 2em auto;
  width: 300px;
  color: #fff;
  line-height: 1.4em;
}

.KeyVisualText * {
  color: white !important;
}

.KeyVisualHeading h1 {
  position: relative;
}

.KeyVisualHeading:before,
.KeyVisualHeading:after {
  content: "";
  position: absolute;
  width: 10vw;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  top: 50%;
}

.KeyVisualHeading:after {
  left: 105%;
}
.KeyVisualHeading:before {
  right: 105%;
}

.tag {
  width: auto;
  height: auto;
  padding: 0.2em;
  margin: -0.2em 0 0 0.4em;
  border-radius: 3px;
  font-size: 85%;
  font-family: Consolas, Andale Mono WT, Andale Mono, Lucida Console,
    Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono,
    Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace;
  text-indent: 0;
  border: none;
  white-space: pre-wrap;
  background: hsl(223, calc(1 * 6.9%), 19.8%);
  color: hsl(210, calc(1 * 2.9%), 86.7%);
  forced-color-adjust: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.contactDetails {
  padding: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.contactDetailIcons {
  margin: 4vh 0 4vh 6.75vw;
  width: calc(50% - 6.75vw);
  overflow-x: auto;
  overflow-y: hidden;
}

.contactDetailIconsTitle,
.gameAccountNameTitle {
  user-select: all;
}

.contactDetailIconsDesc,
.gameAccountName {
  user-select: all;
}

.tag.psrv {
  background: #a45a00;
}

.tag.sexual {
  background: red;
}

.tag.closed {
  background: darkred;
}

.contactDetailIcons svg,
.gameAccount svg {
  font-size: 2rem;
  margin-right: 16px;
}

.contactDetailIcons a,
.contactDetailIcons a:visited,
.gameAccount a,
.gameAccount a:visited {
  text-decoration: none;
  color: #333;
}

.contactDetails h2 {
  width: 100%;
  text-align: center;
}

.contactDetails table {
  max-width: 14rem;
  width: 100%;
  table-layout: fixed;
}

.gameAccountTags {
  display: flex;
  justify-content: right;
  flex-wrap: wrap;
  row-gap: 0.3rem;
}

.contactDetails textarea {
  width: 100%;
  text-align: left;
  margin: 30px 0 0;
  height: 20vh;
  border-color: #333;
  border-radius: 0;
  resize: none;
}

.contactDetails textarea:disabled {
  background: #333;
}

.contactDetails textarea.loading:disabled {
  background: transparent !important;
}

.contactDetails div.leaveAMsgTextareaWrapper {
  width: 100%;
  position: relative;
}

.contactDetails div.leaveAMsgUnlocker {
  width: 100%;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactDetails div.leaveAMsgUnlocker.loading {
  background: transparent !important;
}

.contactDetails div.leaveAMsgUnlocker * {
  color: #fff;
}

.contactDetails .laveAMsgCounter {
  width: 100%;
}

.contactDetails .leaveAMsgSend {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}

.contactDetails .leaveAMsgSend button {
  background: #333;
  color: #fff;
  float: right;
  border-radius: 0;
  width: 20%;
}

.contactDetails .leaveAMsgSend button:hover {
  background: dodgerblue;
  border-color: dodgerblue;
}

.contactDetails .leaveAMsgSend button:disabled {
  background: #999;
}

.contactMsgs {
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.contactMsgs div.profile {
  margin: 0 5rem 0 0;
  overflow-wrap: anywhere;
}

.contactMsgs img.profilePic {
  width: 5rem;
  height: 5rem;
  background: grey;
}

.contactMsgs .socialWrapper {
  display: flex;
  justify-content: right;
  column-gap: 0.5rem;
  margin: 1rem 0 0 0;
}

body.dark-content .contactMsgs .socialWrapper *:not(:nth-last-child(1))::after {
  color: #fff;
}

.contactMsgs .socialWrapper *:not(:nth-last-child(1))::after {
  padding: 0 0 0 0.7rem;
  content: "|";
  color: #767676;
}

body.dark-content .contactMsgs .socialWrapper a,
body.dark-content .contactMsgs .socialWrapper a:visited {
  color: hsl(210, calc(1 * 2.9%), 86.7%);
}

.contactMsgs .socialWrapper a,
.contactMsgs .socialWrapper a:visited {
  text-decoration: none;
  color: #333;
}

.contactMsg {
  background: rgba(0, 0, 0, 0.05);
  padding: 2rem;
}

.gamesDetails {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.gamesDetails.slider > div {
  position: relative;
}

.gamesDetails.slider > div > a > img {
  filter: brightness(50%);
}

.gamesDetails.slider > div > a > p {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 5;
  text-align: center;
  width: 100%;
  text-shadow: -0.1rem -0.1rem 0 #000, 0.1rem -0.1rem 0 #000,
    -0.1rem 0.1rem 0 #000, 0.1rem 0.1rem 0 #000;
  font-weight: 600;
  font-size: larger;
  padding: 0 10px;
}

.gameAccounts {
  display: grid;
  column-gap: 2.5vw;
  row-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  padding: 7vh 20px;
}

@media only screen and (max-width: 801px) {
  .gameAccounts {
    grid-template-columns: repeat(1, 1fr);
  }
}

.gameAccount {
  display: flex;
  justify-content: center;
}

.gameAccount > div {
  align-self: center;
  width: 10rem;
}
.gameAccount > div > table {
  margin: 0 0 0 auto;
}

.tutorialContent p,
.tutorialQuestion p {
  white-space: pre-line;
}

.tutorialContent *:not(pre) > code,
.tutorialQuestion *:not(pre) > code {
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-indent: 0;
  white-space: pre-wrap;
  scrollbar-width: thin;
  scrollbar-color: hsl(216, calc(1 * 7.2%), 13.5%)
    hsl(223, calc(1 * 6.9%), 19.8%);
  background: hsl(223, calc(1 * 6.9%), 19.8%);
  border: 1px solid hsl(216, calc(1 * 7.2%), 13.5%);

  forced-color-adjust: none;

  display: inline-block;
  overflow-x: auto;
  margin: 0 0.25rem 0.25rem 0.25rem;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  color: hsl(216, calc(1 * 3.7%), 73.5%);
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

.tutorialContent pre,
.tutorialQuestion pre {
  border: 1px solid hsl(216, calc(1 * 7.2%), 13.5%);
  border-radius: 4px;
  background: hsl(223, calc(1 * 6.9%), 19.8%) !important;
}

.tutorialContent pre > code,
.tutorialQuestion pre > code {
  background: hsl(223, calc(1 * 6.9%), 19.8%) !important;
}

code,
code * {
  font-family: Consolas, Andale Mono WT, Andale Mono, Lucida Console,
    Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono,
    Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace;
  --font-primary: Whitney, "Microsoft JhengHei", 微軟正黑體,
    "Microsoft JhengHei UI", "Microsoft YaHei", 微軟雅黑, 宋体, SimSun,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.string {
  color: #2aa198 !important;
}

.int {
  color: #2aa198 !important;
}

.keyword {
  color: #859900 !important;
}

.classname {
  color: #b58900 !important;
}

.funcname {
  color: #268bd2 !important;
}

.comment,
.comment * {
  color: hsl(217, calc(1 * 7.6%), 33.5%) !important;
}

*:not(pre) > .qoute {
  width: auto;
  height: auto;
  padding: 0.2em;
  margin: 0 0.5rem 0.1rem 0.5rem;
  border-radius: 3px;
  font-size: 85%;
  font-family: Consolas, Andale Mono WT, Andale Mono, Lucida Console,
    Lucida Sans Typewriter, DejaVu Sans Mono, Bitstream Vera Sans Mono,
    Liberation Mono, Nimbus Mono L, Monaco, Courier New, Courier, monospace;
  text-indent: 0;
  border: none;
  white-space: pre-wrap;

  background: hsl(223, calc(1 * 6.9%), 19.8%);

  forced-color-adjust: none;

  vertical-align: middle;
}

pre {
  margin: 0.5em 0px;
  padding: 1.25em 1em;
}

pre code.qoute {
  white-space: break-spaces;
}

.Game .gameInfo {
  text-align: center;
}

@media only screen and (max-width: 1366px) {
  .Contact h2 {
    margin-top: 5vh;
  }

  .contactDetails {
    padding: 0;
  }

  .contactDetailIcons {
    margin: 3vh 0;
    width: 100%;
  }

  .contactDetailIcons svg {
    display: block;
  }
}

.locale {
  float: right;
  height: 100%;
  display: flex;
  align-items: center;
}

.locale button {
  border: 0;
  margin: 0 5px;
  background: none;
}

.locale button img {
  width: 51.2px;
  height: 37px;
}

.locale button img:hover {
  cursor: pointer;
}

.locale button.selected img:hover {
  cursor: not-allowed;
}

.locale button:not(.selected) img:hover {
  filter: brightness(90%);
}

.locale button img {
  filter: brightness(40%);
}

.locale button.selected img {
  filter: brightness(100%);
  box-shadow: 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff;
  background: #fff;
}

body.dark-content .heading:after {
  background-color: hsl(210, calc(1 * 2.9%), 86.7%);
}

.heading {
  text-transform: uppercase;
  color: #252525;
  text-align: center;
  line-height: 1.5;
  position: relative;
  text-decoration: none;
}

.heading:after {
  content: "";
  width: 8vw;
  height: 2px;
  background-color: #252525;
  position: absolute;
  bottom: 0;
  right: calc(50% - (8vw / 2));
}

.tooltip {
  position: relative;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: rgba(0, 0, 0, 0.95);
  color: #fff;
  padding: 1rem;
  position: absolute;
  top: 0;
}

.instructions > svg {
  margin-right: 1rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  margin-left: 0.5rem;
}

.valid * {
  color: limegreen !important;
}

.invalid {
  margin-left: 0.5rem;
}

.invalid * {
  color: red !important;
}

/* Login Form styles */
.component *:not(button, input) {
  color: #fff;
}

.component h1 {
  font-size: 38px;
}
.component h2 {
  font-size: 30px;
}
.component h3 {
  font-size: 24.72px;
}

.component *:not(h1, h2, h3, h5, h6) {
  font-size: 22px;
}

.component h5 {
  font-weight: normal;
  font-size: 19.28px;
  display: inline;
}
.component h6 {
  font-weight: normal;
  font-size: 18px;
  display: inline;
}

.component form {
  padding-top: 20px;
}

.component button,
.component input {
  border: 0;
  margin-bottom: 10px;
}

.component input {
  padding-left: 10px;
}

.component button {
  padding-top: 5px;
  padding-bottom: 5px;
}

.component .signin,
.component .signup,
.component .signout {
  padding-top: 80px;
  padding-bottom: 70px;
  text-align: center;
  border-bottom: 1px solid #fff;
}

.component .signin button,
.component .signup button,
.component .signout button {
  width: 35%;
}

section.component {
  padding: 2.5rem;
  border-radius: 8px;
}

.component ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
}

.component ul > li {
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
  color: #333;
}

.component ul > li:hover {
  cursor: pointer;
  background-color: dodgerblue;
  color: #fff;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
}

.component .persistCheck {
  font-size: 0.75rem;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.component .persistCheck label {
  margin: 0;
}

.component input[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin: 0 5px 2px 2px;
}

.errMsg {
  visibility: visible;
  min-width: 250px;
  margin-left: -125px;
  background-color: darkred;
  color: #fff;
  text-align: left;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  right: -250px;
  bottom: 12vh;
  opacity: 0;
}

.errMsg.danger {
  right: 0;
  opacity: 1;
  -webkit-animation: fadein 0.5s;
  animation: fadein 0.5s;
}

.errMsg.resolve {
  right: -250px;
  opacity: 0;
  -webkit-animation: fadeout 0.5s;
  animation: fadeout 0.5s;
}

@-webkit-keyframes fadein {
  from {
    right: -250px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    right: -250px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    right: 0;
    opacity: 1;
  }
  to {
    right: -250px;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    right: 0;
    opacity: 1;
  }
  to {
    right: -250px;
    opacity: 0;
  }
}

.background {
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  background-color: dodgerblue;
  background-image: url("./assets/images/cloud.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100% auto;
  z-index: -1;
}

body.dark-content .background.danger {
  background-color: darkred;
  -webkit-animation: slowlydarktored 1s;
  animation: slowlydarktored 1s;
}

body.dark-content .background.resolve {
  -webkit-animation: slowlyredtodark 1s;
  animation: slowlyredtodark 1s;
}

.background.danger {
  background-color: darkred;
  -webkit-animation: slowlybluetored 1s;
  animation: slowlybluetored 1s;
}

.background.resolve {
  -webkit-animation: slowlyredtoblue 1s;
  animation: slowlyredtoblue 1s;
}

@keyframes slowlyredtoblue {
  from {
    background-color: darkred;
  }
  to {
    background-color: dodgerblue;
  }
}

@keyframes slowlybluetored {
  from {
    background-color: dodgerblue;
  }
  to {
    background-color: darkred;
  }
}

@keyframes slowlyredtodark {
  from {
    background-color: darkred;
  }
  to {
    background-color: #2a3439;
  }
}

@keyframes slowlydarktored {
  from {
    background-color: #2a3439;
  }
  to {
    background-color: darkred;
  }
}

/* Loading icon */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #6bb6ff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #6bb6ff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toogleDarkThemeBtnIcon svg > path {
  color: white;
}

.slider {
  display: flex;
  height: 350px;
  max-height: auto;
  overflow-y: hidden;
  overflow-x: scroll !important;
  padding: 16px;
  transform: scroll(calc(var(--i, 0) / var(--n) * -100%));
  scroll-behavior: smooth;
  flex-direction: row;
}

.slider::-webkit-scrollbar {
  height: 5px;
  width: 150px;
  display: none;
}
.slider::-webkit-scrollbar-track {
  background: transparent;
}
.slider::-webkit-scrollbar-thumb {
  background: #888;
}
.slider::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.slider img:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.15);
}
.slide {
  position: relative;
}
.slide img {
  height: 100%;
  width: 250px;
  margin: 0 10px;
  object-fit: cover;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}
.control-prev-btn {
  background-color: rgba(255, 255, 255, 0.55);
  height: 100px;
  line-height: 100px;
  width: 45px;
  text-align: center;
  box-shadow: 0 1px 3px #888;
  user-select: none;
  color: #444;
  cursor: pointer;
}
.control-next-btn {
  background-color: rgba(255, 255, 255, 0.55);
  height: 100px;
  line-height: 100px;
  width: 45px;
  text-align: center;
  box-shadow: 0 1px 3px #888;
  user-select: none;
  color: #444;
  cursor: pointer;
}
.slide img.zoomed {
  width: 500px;
  height: 600px;
  position: fixed;
  left: 25%;
  top: 0%;
  z-index: 1000;
  transform: scale(1) translatey(0) !important;
}
.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.45);
  top: 0;
  display: none;
}
.overlay.active {
  display: block;
}
@media only screen and (max-width: 420px) {
  .slider {
    padding: 0;
  }
  .slide {
    padding: 16px 10px;
  }
  .slide img {
    margin: 0;
  }
  .control-prev-btn {
    top: 37%;
  }
  .control-next-btn {
    top: 37%;
  }
}

.arrows:hover {
  cursor: pointer;
}

.Missing {
  height: calc(100vh - 70px - 70px);
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.Missing button {
  background: none;
  border: none;
  color: #fff;
}

.skills {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: grid;
  column-gap: 2.5vw;
  row-gap: 5vh;
  grid-template-columns: repeat(3, 1fr);
  padding: calc(20px + 3vh) 20px;
}

@media only screen and (max-width: 1366px) {
  .skills {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 801px) {
  .skills {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ProgWidth {
  padding: 0px 20px;
}

.skillDiv {
  height: 5px;
  width: 100%;
  background-color: gray;
}

.skillBar {
  display: block;
  height: 5px;
  background-color: rgb(26, 204, 19);
}

.p {
  display: flex;
  justify-content: space-between;
}

.participated {
  display: grid;
  column-gap: 2.5vw;
  row-gap: 5vh;
  grid-template-columns: repeat(3, 1fr);
  padding: 7vh 20px;
  text-align: center;
}

@media only screen and (max-width: 1366px) {
  .participated {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 801px) {
  .participated {
    grid-template-columns: repeat(1, 1fr);
  }
}

.codeblock {
  position: relative;
}

.codeblock .copy-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 5;
  margin: 0;
  color: rgb(238, 238, 238);
}

.filterSearchBar {
  padding: 2vh 3vh;
  width: 100%;
  border-radius: 20px;
  font-size: 1.15rem;
}
